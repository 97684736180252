.roundedButton {
  border-radius: 12px;
  border: 1px solid;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  // padding: 25px 60px 25px 60px;

  p {
    position: relative;
    z-index: 1;
    transition: color 0.4s linear;
    color: black;
  }

  &:hover {
    p {
      color: white !important;
    }
  }
}

.circle {
  width: 100%;
  height: 150%;
  position: absolute;
  border-radius: 50%;
  top: 100%;
}
