@import url("satoshi.css");

@tailwind base;
@tailwind components;
@tailwind utilities;

.number-loader {
  font-family: "Satoshi-Variable", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 300;
  font-size: 120px;
  line-height: 87%;
  color: #ffffff;
  position: absolute;
  bottom: 100px;
  right: 80px;
}

body {
  font-family: "Satoshi-Variable", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  overflow-x: hidden !important;
  background-color: white;
  color: #2b2b2b;
}
html {
  cursor: url("../Assets/dot-cursor.svg"), auto;
  /* scroll-behavior:auto */
}

[role="button"],
button {
  cursor: url("../Assets/dot-cursor.svg"), auto;
}

a {
  cursor: url("../Assets/dot-cursor.svg"), auto;
}

.center {
  margin: 0 auto;
}

.max-w {
  max-width: 1200px;
}
.input-cursor {
  cursor: url("../Assets/dot-cursor.svg"), auto;
}

.myH1 {
  font-family: "Satoshi-Variable", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 90px;
  font-style: normal;
  font-weight: 300;
  line-height: 103%;
  letter-spacing: -0.02em;
}
.hero-title {
  font-size: 90px;
  line-height: 103%;
  /* or 93px */
  font-weight: 500;
  letter-spacing: -0.02em;

  /* HOVER BOTON */
  background: linear-gradient(
    115.61deg,
    #ff5800 -26.64%,
    #f62a4b 0.76%,
    #a0378b 92.51%,
    #2b417a 104.01%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}
.h2-desk {
  font-family: "Satoshi-Variable";
  font-size: 80px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
}

.sp1-desk {
  font-family: "Satoshi-Variable";
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 43px;
  text-transform: uppercase;
}
.b1-desk {
  font-family: "Satoshi-Variable";
  font-size: 45px;
  font-style: normal;
  font-weight: 300;
  line-height: 61px;
}
.h3-desk {
  font-family: "Satoshi-Variable";
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
}

.p-12 {
  font-family: "Satoshi-Variable";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 20px;
}
.b2-desk {
  font-family: "Satoshi-Variable", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 24px;
  font-style: normal;
  line-height: normal;
}

.button-font {
  font-family: "Satoshi-Variable", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
}

.l-desk {
  font-family: "Satoshi-Variable", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 18px;
  font-weight: 700;
  line-height: 24.3px;
}
.b3-desk {
  font-family: "Satoshi-Variable", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
}

.b4-desk {
  font-family: "Satoshi-Variable", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}
.sp2-desk {
  font-family: "Satoshi-Variable", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-size: 100px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  background: linear-gradient(
    128deg,
    #ff5800 17.82%,
    #f62a4b 34.27%,
    #d42374 48.94%,
    #a0378b 67.62%,
    #64428e 78.29%,
    #2b417a 89.85%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.contact-text-1 {
  /* Body 3 Desktop */
  font-family: "Satoshi-Variable";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 27px;
}

.contact-text-2 {
  font-family: "Satoshi-Variable";
  font-style: normal;
  font-weight: 400;
  font-size: 64px;
  line-height: 86px;
}

.grain {
  z-index: 10000;
  width: 100%;
  height: 100%;
  opacity: 0.05;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.15)
    ),
    url("https://uploads-ssl.webflow.com/63bb1f67f1d82961dd42f3d4/643ceae4fba6fac6078bbf16_grain-effect.gif");
  background-position: 0 0, 50%;
  background-repeat: repeat, repeat;
  background-size: auto, auto;
  background-attachment: scroll, fixed;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

.grain-2 {
  z-index: 200000;
  width: 100%;
  height: 100%;
  opacity: 0.05;
  background-image: linear-gradient(
      rgba(255, 255, 255, 0.15),
      rgba(255, 255, 255, 0.15)
    ),
    url("https://uploads-ssl.webflow.com/63bb1f67f1d82961dd42f3d4/643ceae4fba6fac6078bbf16_grain-effect.gif");
  background-position: 0 0, 50%;
  background-repeat: repeat, repeat;
  background-size: auto, auto;
  background-attachment: scroll, fixed;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
}

#ellipse-shadow {
  position: fixed;
  width: 1167px;
  height: 1167px;
  border-radius: 1167px;
  background: linear-gradient(
    180deg,
    #ff5800 15.63%,
    #f62a4b 34.9%,
    #d42374 52.08%,
    #a0378b 73.96%,
    #64428e 86.46%,
    #2b417a 100%
  );
  filter: blur(300px);
  pointer-events: none; /* To allow interactions with elements beneath it */
  z-index: -5;
  transform: rotate3D(0, 0, 1, 180deg);
}

.work {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: no-repeat;
  background-size: contain;
  background-position-y: bottom;
  /* height: 300px; */
  background-color: #121212;
}

.button-contact {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  background: rgba(255, 255, 255, 0.2);
  border: 1px solid #2b2b2b;
  backdrop-filter: blur(5px);
  border-radius: 12px;
  font-size: 16px;
  font-weight: 500;
  line-height: 21.6px;
}

.contact {
  border-radius: 21.5px;
  border: solid black 1px;
  backdrop-filter: blur(2px);
  width: 107px;
  height: 38px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 0.3s ease;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #212121;
  text-align: center;
  display: flex;
  justify-content: center;
}
.contact.expanded {
  width: 249px;
  height: 267px;
  flex-shrink: 0;
  border-radius: 20px;
  border: 1px solid var(--Gris-1, #b5b5b5);
  background: var(--BG-Blur-Blanco, rgba(255, 255, 255, 0.2));
  backdrop-filter: blur(5px);
}

.contact-form textarea {
  height: 90px;
}
.contact-buttons {
  border: solid 1px #d9d9d9;
  border-radius: 12px;
  width: fit-content;
  padding: 7px 15px 7px 15px;
  margin-right: 17px;
  margin-bottom: 12px;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  text-align: center;
}

label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #8c8c8c;
}
.contact-buttons:hover {
  background-color: #ececec;
  color: black;
}

.contact-b-active {
  background: linear-gradient(
    115.61deg,
    #ff5800 -26.64%,
    #f62a4b 0.76%,
    #a0378b 92.51%,
    #2b417a 100.06%
  );
  color: white !important;
  border: 1px solid #d9d9d9;
}

.contact-form input,
.contact-form select {
  border: none;
  border: 1px solid #9d9d9d75;

  border-radius: 8px;
  padding: 8px 16px 8px 16px;
}

select:focus-visible {
  outline: none;
}

.contact-form input::placeholder,
.contact-form textarea::placeholder {
  font-family: "Satoshi-Variable";
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0em;
}

.contact-form input:focus,
.contact-form textarea:focus {
  outline: none;
}

.contact-page {
  border-radius: 21.5px;
  background: var(--BG-Blur-Blanco, rgba(255, 255, 255));
  backdrop-filter: blur(10px);
  border: solid 1px gray;
  width: 133px;
  height: 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  position: fixed;
  top: 1.5rem;
  right: 18px;
}

.claim-inside {
  overflow-x: auto;
  white-space: nowrap; /* Prevent text wrapping */
  width: 100vw;
  height: 110vh;
  display: flex;
  align-items: center;
}

.work-title {
  font-style: normal;
  font-weight: 700;
  font-size: 65px;
  line-height: 103%;
  letter-spacing: -0.02em;
}

.work-p {
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
}
.work-max-w {
  max-width: 775px;
}
@media (max-width: 1023px) {
  .myH1 {
    font-size: 90px;
  }

  .b1-desk {
    font-family: "Satoshi-Variable";
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 43px;
  }
}

@media (max-width: 640px) {
  .work-title {
    font-size: 45px;
    letter-spacing: -0.02em;
  }

  .work-p {
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
  }

  .myH1 {
    font-family: "Satoshi-Variable";
    font-style: normal;
    font-weight: 300;
    font-size: 70px;
    line-height: 91%;
  }
  .h2-desk {
    font-family: "Satoshi-Variable";
    font-size: 40px;
    font-style: normal;
    font-weight: 300;
    line-height: 54px;
  }
  .sp1-desk {
    font-family: "Satoshi-Variable";
    font-size: 32px;
    font-style: normal;
    font-weight: 700;
    line-height: 43.2px;
    text-transform: uppercase;
  }
  .b1-desk {
    font-family: "Satoshi-Variable";
    font-size: 32px;
    font-style: normal;
    font-weight: 300;
    line-height: 43px;
  }
  .h3-desk {
    font-family: "Satoshi-Variable";
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
  }
  .b2-desk {
    font-family: "Satoshi-Variable";
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 24.3px;
  }
  .l-desk {
    font-size: 16px;
  }
  .b3-desk {
    font-family: "Satoshi-Variable";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 21.6px;
  }

  .b4-desk {
    font-family: "Satoshi-Variable";
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 22px;
    letter-spacing: 0em;
  }
  .sp2-desk {
    font-family: "Satoshi-Variable";
    font-size: 45px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    background: linear-gradient(
      128deg,
      #ff5800 17.82%,
      #f62a4b 34.27%,
      #d42374 48.94%,
      #a0378b 67.62%,
      #64428e 78.29%,
      #2b417a 89.85%
    );
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  .contact.expanded {
    background: rgba(255, 255, 255, 0.6);
    backdrop-filter: (10px);
  }
  .contact-buttons {
    font-size: 13px;
    /* padding: 8px 16px; */
    /* gap: 10px; */
    padding-top: 4px;
    padding-bottom: 4px;
    padding-right: 10px;
    padding-left: 10px;
  }

  .contact-text-1 {
    font-family: "Satoshi-Variable";
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    /* We would love to be part of your project */
  }
  .contact-text-2 {
    font-family: "Satoshi-Variable";
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 61px;
  }
  .number-loader {
    font-family: "Satoshi-Variable", system-ui, -apple-system,
      BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
      "Open Sans", "Helvetica Neue", sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 70px;
    line-height: 87%;
    color: #ffffff;
    position: absolute;
    bottom: 40px;
    right: 45px;
  }
}
@media (max-width: 640px) and (max-height: 600px) {
  .myH1 {
    font-size: 60px;
  }
  .home-desc-hero {
    bottom: 100px;
  }
}

@media (max-width: 365px) {
  .myH1 {
    font-size: 60px;
  }
}
#circleCursor {
  position: fixed;
  width: 37px;
  height: 37px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.2);
  border: solid black 1px;
  pointer-events: none;
  z-index: 100000;
  backdrop-filter: blur(3px);
}

#circleCursor:before {
  content: var(--before-content, "");
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin-top: var(--before-margin-top, "-7px");
}

.button-magnetic {
  display: inline-block;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  box-sizing: border-box;
}

.text {
  pointer-events: initial;
  position: relative;
}

.button--primary {
  position: relative;
  display: inline-block;
  padding: 10px 20px;
  /* width: 100px; */
  /* height: 40px; */
  color: black;
  border-radius: 25px;
  font-weight: 600;
  transition: all 0.3s ease;
  box-shadow: 0 3px 10px rgb(255 255 255 / 15%);
}

.button--primary::before {
  content: "";
  display: inline-block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 25px;
}
.elemento-animado {
  transform-origin: top center;
}

.swift-up-text span {
  display: inline-block;
  overflow: hidden;
  animation: 0.3s swift-up ease-in-out forwards;
}

.swift-up-text i {
  top: 55px; /* Ajusta según tu necesidad */
  animation: 0.5s swift-up ease-in-out forwards;
}

@keyframes swift-up {
  to {
    top: 0;
  }
}

.faq-item {
  border-bottom: 0.5px solid #2b2b2b;
  padding: 16px 2px 16px 2px;
}

.faq-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 90px;
}

.faq-left {
  display: flex;
  align-items: center;
}

.faq-number {
  width: 45px;
  height: 45px;
  color: #0057be;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px;
  font-size: 14px;
  border: #0057be solid 1px;
}

.faq-right {
  font-size: 24px;
  font-weight: bold;
  color: red;
  transition: transform 0.3s ease;
}

.faq-header:hover .faq-right {
  transform: rotate(90deg);
}

.faq-answer {
  overflow: hidden;
  max-height: 0px;
  transition: max-height 0.5s ease; /* Smooth open/close effect */
  margin-top: -10px;
}

.reveal-text {
  display: block;
  overflow: hidden;
}

.reveal-text span {
  display: block;
  /* animation: reveal 2s cubic-bezier(0.77, 0, 0.175, 1) 0s; */
  animation: reveal 2s cubic-bezier(0.77, 0, 0.175, 1) backwards;
  z-index: 100;
}

.reveal-text-span {
  display: block;
  overflow: hidden;
}

.reveal-span {
  display: block; /* Mantén esto para el efecto de revelado */
  animation: reveal 2s cubic-bezier(0.77, 0, 0.175, 1) backwards;
  z-index: 100;
  overflow: hidden;
}

@keyframes reveal {
  0% {
    transform: translate(0, 150%);
  }
  100% {
    transform: translate(0, 0);
  }
}
.hero-text {
  display: flex;
  flex-wrap: wrap;
}

.heroAnimation {
  display: inline-block;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 0.7s ease, transform 0.7s ease;
}
