.inside-cont {
  background: no-repeat;
  background-image: url("../Assets/work.svg");
  background-size: 60vw;
  /* background-position: center; */
  background-position: 50% 15%;

  background-color: #121212;
}

.firstWord {
  background: no-repeat;
  background-image: url("../Assets/work.svg");
  background-size: 60vw;
  /* background-position: center; */
  background-position: 50% 3%;

  background-color: #121212;
}

.work-mob {
  background: no-repeat;
  background-image: url("../Assets/work.svg");
  background-size: contain;
  background-position: right 200px;
  background-color: #121212;
}
.scroll-content {
  /* border: green solid 2px; */
  /* margin-top: -350px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.div-animado {
  width: 100vw;
  height: auto;
  /* height: 500px; */
  z-index: 9999;
}

.project-container {
  display: flex;
  justify-content: space-around;
  align-items: flex-end;
}
.work-item {
  cursor: none !important;
  /* transition: opacity 2s; */
  border-radius: 12px;
  transition: 0.3s;
}
.transition-transform {
  transition: transform 0.7s ease-in-out, opacity 0.5s ease-in-out;
}

.translate-y-full {
  transform: translateY(100%); /* Starts below the viewport */
}

.translate-y-0 {
  transform: translateY(0); /* Moves to its original position */
}

.opacity-0 {
  opacity: 0;
}

.opacity-100 {
  opacity: 1;
}

.project-info {
  opacity: 0;
  display: block;
  transition: opacity 0.5s;
  padding: 25px 20px;
}

.work-item:hover .project-info {
  opacity: 1;
}

.work-item:hover {
  border-radius: 32px;
  transition: 0.3s;
}

.blur-bg {
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(17.5px);
}

.tags {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0px 10px;
  gap: 10px;
  background: #e1e1e1;
  border-radius: 8px;
}
.bullet-work {
  width: 10px;
  font-size: 20px;
  position: absolute;
  top: 10px;
  margin-left: 11px;
}

.first-pro {
  /* background-image: url("../Assets/work/work_1.png"); */
  background-size: contain;
  background-repeat: no-repeat;
  width: 552px;
  height: 552px;
  left: 10%;
  /* margin-bottom: 0px; */
}
.second-pro {
  /* background-image: url("../Assets/work/work-22.png"); */
  background-size: contain;
  background-repeat: no-repeat;
  width: 450px;
  height: 450px;
  right: 10%;
}

.third-pro {
  /* background-image: url("../Assets/work/work_4.png"); */
  background-size: contain;
  background-repeat: no-repeat;
  width: 450px;
  height: 450px;
  left: 10%;
}

.fourth-pro {
  /* background-image: url("../Assets/work/work_3.png"); */
  background-size: contain;
  background-repeat: no-repeat;
  width: 552px;
  height: 552px;
  right: 10%;
}

.fourth-section {
  background: #121212;
  /* height: 500px; */
  height: 222px;
}

/* .claim-cont {
  height: 300px;
  background: #121212;
} */

.claim-container {
  display: block;
}

.claim {
  color: #b5b5b5;
  /* margin-left: 180px; */
  margin-top: -450px;
  /* margin-top: -800px; */
}

.small-numb {
  font-family: "Satoshi-Variable";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
.mix-diff {
  /* mix-blend-mode: difference; */
}

.services {
  display: flex;
  width: 311px;
  height: 120px; /* Default height */
  justify-content: flex-start;
  padding: 0;
  transition: height 0.3s ease; /* Only transition height */
  cursor: none !important;
  margin-bottom: 12px;
  background: #f2f2f2;
  border: 1px solid #b5b5b5;
  border-radius: 20px;
  user-select: none;
}

.expDesign {
  height: 203px !important;
  background: rgba(255, 255, 255, 0.2);
  color: white !important;
}
.expCode {
  height: 203px !important;
  background: rgba(255, 255, 255, 0.2);
  color: white !important;
}
.expSupport {
  height: 203px !important;

  background: rgba(255, 255, 255, 0.2);

  color: white !important;
}

:root {
  --card-height: 410px;
  --card-margin: 4vw;
  --card-top-offset: 1em;
  --numcards: 6;
  --outline-width: 1px;
}

#card_1 {
  --index: 1;
  top: 50px;
  left: 1px;
}

#card_2 {
  --index: 2;
}

#card_3 {
  --index: 3;
}

#card_4 {
  --index: 4;
}

#card_5 {
  --index: 5;
}
#card_6 {
  --index: 6;
}

.card {
  position: sticky;
  top: 250px;
  /* top: 15px; */
  padding-top: calc(var(--index) * var(--card-top-offset)) !important;
  display: flex;
  justify-content: center;
}

header,
main {
  width: 100vw;
  margin: 0 auto;
}

/* .header {
  height: 100vh;
  display: grid;
  place-items: center;
} */

#cards {
  display: grid;
  gap: var(--card-margin);
  grid-template-columns: 1fr;
  grid-template-rows: repeat(var(--numcards), var(--card-height));
  list-style: none;
  --numcards: 5 !important;
  outline: none;
  view-timeline-name: --cards-element-scrolls-in-body !important;
}

@keyframes scale {
  to {
    transform: scale(calc(1.1 - calc(0.1 * var(--reverse-index))));
  }
}

.card-inside {
  display: flex;
  align-items: center;
}

/* .card__content > figure {
  grid-area: img;
  overflow: hidden;
}
*/

/* .card__content > figure > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
} */

.caro-inside {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 80%;
  /* margin-right: 20px; */
  /* margin-left: 20px; */
}

.card {
  --index0: calc(var(--index) - 1) !important;
  --reverse-index: calc(var(--numcards) - var(--index0)) !important;
  --reverse-index0: calc(var(--reverse-index) - 1) !important;
}

.card__content {
  --start-range: calc(var(--index0) / var(--numcards) * 100%) !important;
  box-shadow: 0 0.2em 1em rgba(0, 0, 0, 0.1), 0 1em 2em rgba(0, 0, 0, 0.1);
  background: rgb(255, 255, 255);
  color: rgb(10, 5, 7);
  border-radius: 1em;
  overflow: hidden;
  border: solid 1px #b5b5b5;
  width: 870px;
  height: 340px;
  display: flex;
  animation: linear scale forwards !important;
  animation-range: exit-crossing var(--start-range) exit-crossing
    var(--end-range) !important;
}
.card__animation {
  animation-timeline: --cards-element-scrolls-in-body !important;
}

/* card terimna !!!!!!!!!!!!!!!!!!!!!!!!!!!! */

.swiper.swiper-initialized.swiper-horizontal.mySwiper {
  background: rgb(236, 236, 236);
  background: linear-gradient(
    90deg,
    rgba(236, 236, 236, 0.8898153011204482) 11%,
    rgba(178, 206, 241, 0) 18%,
    rgba(255, 255, 255, 0) 82%,
    rgba(236, 236, 236, 0.3463979341736695) 88%
  );
  /* margin-left: 9rem; */
  /* margin-right: 9rem; */
}

.left-blur {
  position: absolute;
  top: 0px;
  position: absolute;
  width: 350px;
  height: 205px;
  left: 0px;
  top: 0px;
  height: 120px;
  z-index: 11;
  background: linear-gradient(
    90deg,
    rgb(236, 236, 236) 37%,
    rgba(242, 242, 242, 0) 100%
  );
}
.right-blur {
  position: absolute;
  top: 0px;
  position: absolute;
  width: 350px;
  height: 205px;
  right: 0px;
  top: 0px;
  height: 120px;
  z-index: 11;
  background: linear-gradient(
    270deg,
    rgb(236, 236, 236) 37%,
    rgba(242, 242, 242, 0) 100%
  );
}
.prefooter {
  height: 636px;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  border-radius: 0px 0px 60px 60px;
  background-image: url("../Assets/contactus.png");
  transition: transform 0.3s ease; /* Agregar una transición para suavizar la animación */
  background-size: cover;
}

.prefooter.shrink {
  transform-origin: center calc(100% - 600px);
  transform: translate3d(0px, 0vh, 0px) scale(0.95);
}

.border-t-gray {
  border-top: 1px solid #b5b5b5;
  margin-left: 128px;
  margin-right: 128px;
  display: flex;
  justify-content: center;
  padding-bottom: 75px;
}
.sayhi {
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  background: linear-gradient(
    99.94deg,
    #ff5800 -0.95%,
    #f62a4b 21.56%,
    #d42374 42.07%,
    #a0378b 61.32%,
    #64428e 78.44%,
    #2b417a 98.59%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.sayhi-2 {
  font-family: "Satoshi-Variable", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  text-align: center;
  background: linear-gradient(
    99.94deg,
    #ff5800 -0.95%,
    #f62a4b 21.56%,
    #d42374 42.07%,
    #a0378b 61.32%,
    #64428e 78.44%,
    #2b417a 98.59%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.sayhi-2:hover {
  color: white !important;
}

.t-mail {
  font-family: "Satoshi-Light";
  font-size: 19px;
  font-weight: 300;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}

.privacy-font {
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #212121;
  margin-top: 35px;
}
.contacts-home {
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 300;
  font-size: 19px;
  line-height: 26px;
  padding: 8px 20px;
  border: 1px solid #b5b5b5;
  border-radius: 20px;
  text-align: center;
}

.click-to-copy {
  font-family: "Satoshi-Regular";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #212121;
}
.number-cont {
  display: flex;
  justify-content: center;
  width: 250px;
}

.hover-text {
  display: none;
}

.whatsapp-link:hover .contacts-home {
  display: none;
}

.whatsapp-link:hover .hover-text {
  display: block;
}

/* .services-cont { */
/* margin-bottom: 400px;
} */

.labba-lg-bottom {
  display: flex;
}

.labba-bottom {
  display: none;
}

.home-desc-hero {
  position: absolute;
  bottom: 5rem;
  right: 21%;
}

.content-claim {
}
.content-inside {
}

@media (max-width: 1200px) {
  .labba-bottom {
    display: flex;
  }
  .labba-lg-bottom {
    display: none;
  }
}

@media (max-width: 1320px) {
  .first-pro {
    left: 5%;
  }
  .second-pro {
    right: 5%;
  }
  .third-pro {
    left: 5%;
  }
  .fourth-pro {
    right: 5%;
  }
}

@media (max-width: 1150px) {
  .first-pro {
    left: 3%;
    width: 500px;
    height: 500px;
  }
  .second-pro {
    right: 3%;
    width: 410px;
    height: 410px;
  }
  .third-pro {
    left: 3%;
    width: 410px;
    height: 410px;
  }
  .fourth-pro {
    right: 3%;
    width: 500px;
    height: 500px;
  }
}

@media (max-width: 1260px) {
  .claim-container {
    display: none;
  }
}

@media (min-width: 1000px) and (max-height: 690px) {
  /* Your styles for the specified viewport dimensions go here */
  .h1-desk {
    margin-right: 100px;
    font-size: 100px;
  }
  .home-desc-hero {
    bottom: 11rem;
  }
}

@media (max-width: 640px) {
  .project-info {
    display: none;
  }

  .swiper.swiper-initialized.swiper-horizontal.mySwiper {
    margin-top: 50px;
    padding-bottom: 80px;
  }

  .first-pro {
    /* background-image: url("../Assets/work/work_1.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    width: 320px;
    height: 320px;
    /* position: absolute; */
    border-radius: 20px;
    left: 13px;
    top: 1200px;
    margin-bottom: 150px;
  }

  .second-pro {
    /* background-image: url("../Assets/work/work_2.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    width: 320px;
    height: 320px;
    /* position: absolute; */
    border-radius: 20px;
    right: 13px;
    top: 1700px;
  }
  .third-pro {
    /* background-image: url("../Assets/work/work_4.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    width: 320px;
    height: 320px;
    margin-bottom: 150px;
    /* position: absolute; */
    border-radius: 20px;
    left: 13px;
    top: 2200px;
  }
  .fourth-pro {
    /* background-image: url("../Assets/work/work_3.png"); */
    background-size: cover;
    background-repeat: no-repeat;
    width: 320px;
    height: 320px;
    /* position: absolute; */
    border-radius: 20px;
    right: 13px;
    top: 2530px;
  }

  .next-level {
    display: flex;
    height: 400px;
    padding-left: 25px;
    padding-right: 25px;
    flex-direction: column;
    padding-top: 70px;
    align-items: center;
  }
  .prefooter {
    height: 401px;
  }
  .border-t-gray {
    padding-bottom: 55px;
    margin-left: 12px;
    margin-right: 12px;
  }
  .div-animado {
    height: 380px;
  }
  .swiper.swiper-initialized.swiper-horizontal.mySwiper {
    background: none !important;
  }
  .background-mobile {
    position: fixed;
    background: linear-gradient(
      180deg,
      #ff5800 15.62%,
      #f62a4b 34.9%,
      #d42374 52.08%,
      #a0378b 73.96%,
      #64428e 86.46%,
      #2b417a 100%
    );
    filter: blur(115px);
    height: 50vh;
    width: 100vw;
    z-index: -1;
  }
  .services-cont {
    /* height: auto; */
    margin-bottom: 140px;
  }

  .home-desc-hero {
    bottom: 0rem;
    right: 5%;
  }
  .claim {
    margin-top: -650px;
  }
}

@media (max-width: 640px) and (max-height: 600px) {
  .home-desc-hero {
    bottom: 60px;
  }
}

/* geiwfdjughtyrfehudijwokibuhfd */

#container .box {
  width: 100vw;
  height: 100vh;
  display: block;
  position: relative;
}
#container .box > div {
  width: 100px;
  height: 100px;
  /* font-size: 96px; */
  color: #fff;
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -50px 0 0 -50px;
  line-height: 0.7;
  font-weight: bold;
}
#container {
  overflow-y: scroll;
  overflow-x: hidden;
  transform: rotate(270deg) translateX(-100%);
  transform-origin: top left;
  background-color: #121212;
  width: 100vh;
  height: 100vw;
}
#container2 {
  transform: rotate(90deg) translateY(-100vh);
  transform-origin: top left;
  white-space: nowrap;
  font-size: 0;
}

.one {
  background-color: #121212;
}
.two {
  background-color: #121212;
}

/* .mySwiper .swiper-wrapper {
  transition-timing-function: linear;
} */

.mySwiper .swiper-wrapper {
  display: flex;
  animation: scroll 30s linear infinite; /* Ajusta la duración de la animación */
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

@media (max-width: 1024px) {
  .card__content {
    width: 680px;
    height: 300px;
  }
  .card-inside {
    width: 100%;
  }
}

@media (max-width: 768px) {
  .card__content {
    width: 600px;
    height: 280px;
  }

  .svg-class-name {
    width: 75px !important;
    height: 75px !important;
  }

  .project-info {
    opacity: 1;
  }
  .left-blur {
    display: none;
  }
  .right-blur {
    display: none;
  }
}

@media (min-width: 1000px) and (max-height: 690px) {
  .card__content {
    width: 760px !important;
    height: 260px !important;
  }
}

@media (max-width: 640px) {
  .card__content {
    width: 330px !important;
    height: 330px !important;
  }
  .card__content {
    animation: none !important;
  }

  .card-inside {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-left: 30px;
    margin-top: 20px;
    margin-right: 30px;
  }

  .number-cont {
    display: inline;
    width: 0px;
  }
  .svg-class-name {
    width: 60px !important;
    height: 60px !important;
    margin-top: 17px;
  }
}

.button-contact-submit {
  transition: background-color 0.3s ease, color 0.3s ease;
}

.button-contact-submit.submitting {
  background-color: #ccc;
  color: #999;
  cursor: not-allowed;
}

.button-contact-submit.thank-you {
  background-color: #4caf50;
  color: #fff;
}

.fade-in {
  animation: fadeIn 0.5s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.loader {
  position: relative;
  width: 100%;
  height: 30px; /* Adjust height as needed */
  /* border: 1px solid #ccc; */
  border-radius: 0px;
}

/* Prueba.css */

.progress-bar {
  width: 100%;
  height: 100%;
  background-color: #121212;
}

.progress {
  height: 100%;
  background: linear-gradient(
    100.04deg,
    #f62a4b 1.68%,
    #a0378b 50.82%,
    #2b417a 113.3%
  );
  text-align: center;
  color: #fff;
  line-height: 50px; /* Center the text vertically */
  transition: width 0.3s ease;
}

.loading {
  cursor: wait;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 20000000;
  background-color: #121212;
}

.fade-out {
  animation: fadeOut 0.8s forwards;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.drop-us {
  width: 218px;
  height: 77px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid white 2px;
  border-radius: 38.5px;
}

.coolBeans {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-decoration: none;
  transition: 0.2s transform ease-in-out;
  will-change: transform;
  z-index: 0;

  background-color: transparent;
  border: 1px solid #b5b5b5;
  border-radius: 38.5px;
  width: 218px;
  height: 77px;
}

.coolBeans::after {
  background-image: linear-gradient(
    126.48deg,
    #f62a4b -2.96%,
    #d42374 47.65%,
    #a0378b 103.59%
  );
  color: white;
  border-radius: 3rem;
  content: "";
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-100%, 0) rotate(10deg);
  transform-origin: top left;
  transition: 0.2s transform ease-out;
  will-change: transform;
  z-index: -1;
}

.coolBeans:hover::after {
  transform: translate(0, 0);
}

.coolBeans:hover {
  border: 2px solid transparent;
  color: white !important;
  transform: scale(1.05);
  will-change: transform;
}

.btn-floral-border {
  border: 1px solid #b5b5b5;
  border-radius: 12px;
  height: 44px;
  width: 220px;
}

.btn-floral-scent {
  position: relative;
  width: 220px;
  height: 42px;
  overflow: hidden;
  border: none;
  color: #2b2b2b;
  font-size: 16px;
}
.btn-floral-scent::before {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  transition: all 0.3s;
}
.btn-floral-scent::after {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 60px;
  left: 0;
  width: 100%;
  height: 100%;

  transition: all 0.3s;
}
.btn-floral-scent:hover::before {
  top: -60px;
}
.btn-floral-scent:hover::after {
  top: 0;
}

/* 


*/
.margin-hero {
  margin-top: 9rem;
}

@media (max-height: 620px) {
  .margin-hero {
    margin-top: 4rem !important;
  }
  .none {
    display: none;
  }
}

@media (max-width: 1410px) {
  .margin-hero {
    margin-top: 7rem;
  }
}
