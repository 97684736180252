@font-face {
  font-family: "Satoshi-Variable";
  src: url("../Assets/Fonts/Satoshi-Variable.woff2") format("woff2"),
    url("../Assets/Fonts/Satoshi-Variable.woff") format("woff"),
    url("../Assets/Fonts/Satoshi-Variable.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-VariableItalic";
  src: url("../Assets/Fonts/Satoshi-VariableItalic.woff2") format("woff2"),
    url("../Assets/Fonts/Satoshi-VariableItalic.woff") format("woff"),
    url("../Assets/Fonts/Satoshi-VariableItalic.ttf") format("truetype");
  font-weight: 300 900;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Light";
  src: url("../Assets/Fonts/Satoshi-Light.woff2") format("woff2"),
    url("../Assets/Fonts/Satoshi-Light.woff") format("woff"),
    url("../Assets/Fonts/Satoshi-Light.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-LightItalic";
  src: url("../Assets/Fonts/Satoshi-LightItalic.woff2") format("woff2"),
    url("../Assets/Fonts/Satoshi-LightItalic.woff") format("woff"),
    url("../Assets/Fonts/Satoshi-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Regular";
  src: url("../Assets/Fonts/Satoshi-Regular.woff2") format("woff2"),
    url("../Assets/Fonts/Satoshi-Regular.woff") format("woff"),
    url("../Assets/Fonts/Satoshi-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-Italic";
  src: url("../Assets/Fonts/Satoshi-Italic.woff2") format("woff2"),
    url("../Assets/Fonts/Satoshi-Italic.woff") format("woff"),
    url("../Assets/Fonts/Satoshi-Italic.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Medium";
  src: url("../Assets/Fonts/Satoshi-Medium.woff2") format("woff2"),
    url("../Assets/Fonts/Satoshi-Medium.woff") format("woff"),
    url("../Assets/Fonts/Satoshi-Medium.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-MediumItalic";
  src: url("../Assets/Fonts/Satoshi-MediumItalic.woff2") format("woff2"),
    url("../Assets/Fonts/Satoshi-MediumItalic.woff") format("woff"),
    url("../Assets/Fonts/Satoshi-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Bold";
  src: url("../Assets/Fonts/Satoshi-Bold.woff2") format("woff2"),
    url("../Assets/Fonts/Satoshi-Bold.woff") format("woff"),
    url("../Assets/Fonts/Satoshi-Bold.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-BoldItalic";
  src: url("../Assets/Fonts/Satoshi-BoldItalic.woff2") format("woff2"),
    url("../Assets/Fonts/Satoshi-BoldItalic.woff") format("woff"),
    url("../Assets/Fonts/Satoshi-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-display: swap;
  font-style: italic;
}

@font-face {
  font-family: "Satoshi-Black";
  src: url("../Assets/Fonts/Satoshi-Black.woff2") format("woff2"),
    url("../Assets/Fonts/Satoshi-Black.woff") format("woff"),
    url("../Assets/Fonts/Satoshi-Black.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Satoshi-BlackItalic";
  src: url("../Assets/Fonts/Satoshi-BlackItalic.woff2") format("woff2"),
    url("../Assets/Fonts/Satoshi-BlackItalic.woff") format("woff"),
    url("../Assets/Fonts/Satoshi-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-display: swap;
  font-style: italic;
}

.satoshi {
  font-family: "Satoshi-Variable", system-ui, -apple-system, BlinkMacSystemFont,
    "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue",
    sans-serif;
}
